<script>
import formatGqlError from '~/helpers/formatGqlError.js';
import likeMutation from '~/graphql/mutations/like/like.graphql';
import unlikeMutation from '~/graphql/mutations/like/unlike.graphql';

/**
 * This is a renderless component which acts as a wrapper around another element (like a button) to like / unlike an entry.
 * You could further wrap this in another component to standardize the various props to pass. (Eg. a like-toggle component)
 *
 * Example usage:
 *
 * ```
 * <template>
 *      <like :like.sync="iHaveLikedThis" likeable-type="UPDATE" :likeable-id="1" v-slot="{ clickHandler : handleLike }">
 *          <asc-button @click="handleLike">
 *              <template v-if="myFollowingBoolean">Unlike</template>
 *              <template v-else>Like</template>
 *          </asc-button>
 *      </like>
 * </template>
 * ```
 */
export default {
    props: {
        liked: {
            type: Boolean,
            required: true,
            default: false
        },
        likeableType: {
            type: String,
            required: true,
            default: 'topic'
        },
        likeableId: {
            type: Number,
            required: true,
            default: null
        }
    },

    data() {
        return {
            state: null,
            error: null
        };
    },

    methods: {
        async clickHandler() {
            this.state = 'loading';
            try {
                if (!this.liked) {
                    await this.$gql.executeQuery(likeMutation, { type: this.likeableType, id: this.likeableId });

                    // Emit two-way-binding
                    this.$emit('update:liked', !this.liked);
                } else if (this.$auth.loggedIn) {
                    // You can only unlike when logged in, as we otherwise wouldn't know which record to remove.
                    await this.$gql.executeQuery(unlikeMutation, { type: this.likeableType, id: this.likeableId });

                    // Emit two-way-binding
                    this.$emit('update:liked', !this.liked);
                }

                this.state = 'success';
            } catch (e) {
                const messages = formatGqlError(e);

                messages.forEach((message) => {
                    this.$store.commit('toasts/addMessage', {
                        message: message.text,
                        status: 'error'
                    });
                });
                this.error = e;
                this.state = 'error';
            }
        }
    },

    render() {
        return this.$scopedSlots.default({
            clickHandler: this.clickHandler,
            state: this.state,
            error: this.error
        });
    }
};
</script>
