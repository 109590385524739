<script>
import { marked } from 'marked';
import { escape } from 'lodash';

marked.setOptions({
    headerIds: false,
    breaks: true
});

export default {
    props: {
        markdown: {
            type: String,
            default: null
        }
    },

    computed: {
        markdownString() {
            return this.markdown || this.slotMarkdown || '';
        },

        html() {
            let md = escape(this.markdownString);

            md = md.replace(/<br>/g, '\n'); // Replace any leftover <br> tags with \n
            md = md.replace(/\\&lt;/g, '&lt;'); // Replace any escaped \&lt; tags with non-escaped ones.

            return marked(md);
        },

        slotMarkdown() {
            return this.$slots.default && this.$slots.default[0].text;
        }
    },

    render(createElement) {
        return createElement('div', { domProps: { innerHTML: this.html } });
    }
};
</script>
