/**
 * Check if the provided user is an admin
 *
 * @return  {Boolean}  User is admin
 */
export const isAdmin = function(user) {
    if (!user || !user.roles) {
        return false;
    }

    return user.roles.findIndex(({ name }) => name === 'admin') !== -1;
};
