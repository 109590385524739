export function getMostUsedChannelIdForEntry(entry) {
    if (!entry.topics || !entry.topics.length) {
        return null;
    }

    // If a channel is directly associated with the entry, use that
    const directlyAssociatedChannels = entry.topics.filter(topic => topic.level === 0);

    let channelIds;
    if (directlyAssociatedChannels.length) {
        channelIds = directlyAssociatedChannels.map(topic => topic.id);
    } else {
        // Check the topic parents for the used topics for the most used
        channelIds = entry.topics.filter(topic => topic.parent).map((topic) => {
            return topic.parent.id;
        });
    }

    // Get the most used channel ID from the map
    return channelIds.sort((a, b) =>
        channelIds.filter(v => v === a).length -
                channelIds.filter(v => v === b).length
    ).pop();
}

/**
 * Given a channel object, returns a flat array containing the IDs of it and its children.
 *
 * Useful when filtering updates because they may be attached to either a channel directly,
 * or one of its child topics.
 *
 * @param {*} channel
 */
export function channelAndChildrenIds(channel) {
    let ids = [channel.id];
    if (channel.children.length) {
        ids = ids.concat(channel.children.map(child => child.id));
    }
    return ids;
}
