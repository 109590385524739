<template>
    <footer class="update-card-footer">
        <asc-avatar
            :user="update.author || null"
            class="update-card-footer__avatar avatar--small"
            @click.native.stop
        />

        <div class="update-card-footer__icon">
            <component :is="cardIconComponent" v-if="cardIconComponent" />
        </div>

        <span class="update-card-footer__label">{{ cardLabel }}</span>

        <asc-icon
            v-if="postByAdmin"
            icon="amsterdamsmartcity"
            class="update-card-footer__admin"
            title="This update was posted by someone from Amsterdam Smart City"
        />

        <div class="update-card-footer__counters">
            <asc-like
                v-slot="{ clickHandler : toggleLike }"
                likeable-type="UPDATE"
                :likeable-id="Number(update.id)"
                :liked="likedByMe"
                @update:liked="updateLikedState"
            >
                <update-counter
                    icon="like"
                    :data-toggled="likedByMe"
                    :title="`${update.like_count} ${update.like_count === 1 ? 'person' : 'people'} liked this`"
                    @click.native.prevent.stop="toggleLike"
                >
                    {{ likeCountLabel }}
                </update-counter>
            </asc-like>

            <update-counter
                icon="comment"
                :title="`${update.comment_count} comments`"
                :url="{ path: `/updates/${update.update_type}/${update.slug}`, hash: '#comments' }"
                @click.stop
            >
                {{ commentCount }}
            </update-counter>
        </div>
    </footer>
</template>

<script>
import { format, isSameDay, parseISO } from 'date-fns';
import { upperFirst } from 'lodash';
import { isAdmin } from '~/helpers/user';

import AscAvatar from '~/patterns/atoms/avatar/avatar.vue';
import AscIcon from '~/patterns/atoms/icon/icon.vue';
import EventIcon from '~/patterns/atoms/icon/presets/update-types/event.vue';
import NewsIcon from '~/patterns/atoms/icon/presets/update-types/news.vue';
import OpportunityIcon from '~/patterns/atoms/icon/presets/update-types/opportunity.vue';
import ProjectIcon from '~/patterns/atoms/icon/presets/update-types/project.vue';
import RequestIcon from '~/patterns/atoms/icon/presets/update-types/request.vue';
import ExperienceIcon from '~/patterns/atoms/icon/presets/update-types/experience.vue';
import UpdateCounter from '~/patterns/atoms/update-counter/update-counter.vue';
import AscLike from '~/patterns/atoms/like/like.vue';

export default {
    components: {
        AscAvatar,
        EventIcon,
        NewsIcon,
        OpportunityIcon,
        ProjectIcon,
        RequestIcon,
        ExperienceIcon,
        UpdateCounter,
        AscIcon,
        AscLike
    },

    props: {
        update: {
            type: Object,
            required: true,
            default: () => {}
        },

        channel: {
            type: Object,
            required: false,
            default: null
        }
    },

    data() {
        return {
            likedByMe: false,
            likeCount: 0
        };
    },

    computed: {
        /**
         * Build the component name for the various update-type icons
         */
        cardIconComponent() {
            if (this.update.update_type) {
                return this.update.update_type + '-icon';
            }
            return null;
        },

        /**
         * Display event date info, the channel, or fallback to the update type.
         */
        cardLabel() {
            if (this.update.update_type === 'event') {
                try {
                    let value = `${this.update.event_type || 'Event'}`;

                    if (this.update.event_startdate) {
                        const parsedStartDate = parseISO(this.update.event_startdate);
                        let showEndDate = false;
                        if (this.update.event_enddate) {
                            showEndDate = !isSameDay(parsedStartDate, parseISO(this.update.event_enddate));
                        }

                        if (showEndDate) {
                            value += ` from ${format(parsedStartDate, this.$config.dateFormat.dayOfMonth)}`;
                            value += ` to ${format(parseISO(this.update.event_enddate), this.$config.dateFormat.dayOfMonth)}`;
                        } else {
                            value += ` on ${format(parsedStartDate, this.$config.dateFormat.dayOfMonth)}`;
                        }
                        return value;
                    }
                } catch (e) {
                    return this.update.event_type || 'Event';
                }
            }

            if (this.channel) {
                return '#' + this.channel.title.replace(/\s/g, '');
            }

            return upperFirst(this.update.update_type);
        },

        postByAdmin() {
            if (this.update.author) {
                return isAdmin(this.update.author);
            }

            return false;
        },

        likeCountLabel() {
            return this.likeCount === 0 ? '+' : this.likeCount;
        },

        commentCount() {
            return this.update.comment_count === 0 ? '+' : this.update.comment_count;
        }
    },

    watch: {
        'update.like_count': {
            immediate: true,
            handler(value) {
                this.likeCount = value;
            }
        },

        'update.liked_by_me': {
            immediate: true,
            handler(value) {
                this.likedByMe = value;
            }
        }
    },

    methods: {
        updateLikedState(value) {
            this.likedByMe = value;

            if (value === true) {
                this.likeCount++;
            } else {
                this.likeCount--;
            }
        }
    }
};
</script>

<style lang="less" src="./update-card-footer.less" />
