<template>
    <article class="card" :style="tint">
        <div v-if="!!$slots.banner" class="card__banner">
            <slot name="banner" />
        </div>
        <div class="card__prefix">
            <slot name="prefix">
                <template v-if="update.is_highlighted">
                    <span class="card__highlight">Highlight</span> from
                </template>
                <template v-if="update.author">
                    {{ update.author.name }},
                    <template v-if="update.author.profession">
                        {{ update.author.profession }}
                        <template v-if="update.author.organisations && update.author.organisations.length"> at {{ update.author.organisations[0].title }}</template>,
                    </template>
                </template>
                <template v-else>Anonymous</template>
                posted <time :datetime="update.created_at" :title="update.created_at">{{ formattedCreationDate }}</time>
            </slot>
        </div>

        <div class="card__body" @click="goToUpdate">
            <slot>
                <header class="card__header">
                    <h2 class="card__title">
                        <slot name="title">
                            <nuxt-link :to="`/updates/${update.update_type}/${update.slug}`">
                                {{ update.title }}
                            </nuxt-link>
                        </slot>
                    </h2>

                    <img
                        v-if="update.featured_image"
                        :src="update.featured_image"
                        class="card__image"
                        alt="Featured image"
                        loading="lazy"
                    >
                </header>

                <div class="card__message">
                    <slot name="message">
                        <asc-markdown>{{ update.message }}</asc-markdown>
                    </slot>
                </div>

                <slot name="footer">
                    <update-card-footer :update="update" :channel="channel" class="card__footer" />
                </slot>
            </slot>
        </div>
    </article>
</template>

<script>
import { format, formatDistanceToNow, parseISO, isToday, isThisYear } from 'date-fns';

import UpdateCardFooter from '~/patterns/molecules/update-card-footer/update-card-footer.vue';
import AscMarkdown from '~/patterns/atoms/markdown-renderer/markdown-renderer.vue';

import { getMostUsedChannelIdForEntry } from '~/helpers/topics';

export default {
    components: {
        UpdateCardFooter,
        AscMarkdown
    },

    props: {
        update: {
            type: Object,
            required: true,
            default: () => {}
        }
    },

    computed: {
        channel() {
            const channelId = getMostUsedChannelIdForEntry(this.update);
            if (channelId) {
                // Find the channel and its colour in store.
                return this.$store.getters['topics/channelById'](channelId);
            }
            return null;
        },

        tint() {
            if (this.channel?.color) {
                return `--card-tint: var(--color-tint-${this.channel.color})`;
            }
            return '';
        },

        formattedCreationDate() {
            const date = parseISO(this.update?.created_at);

            if (isToday(date)) {
                return formatDistanceToNow(date, { addSuffix: true });
            } else if (isThisYear(date)) {
                return format(date, this.$config.dateFormat.dayOfMonth);
            }
            return format(date, this.$config.dateFormat.dayMonthYear);
        }
    },

    methods: {
        goToUpdate(e) {
            if (e.target.className !== 'update-counter' && e.target.className !== 'update-counter__count') {
                this.$router.push(`/updates/${this.update.update_type}/${this.update.slug}`);
            }
        }
    }
};
</script>

<style lang="less" src="./card.less" />
