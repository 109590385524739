<template>
    <component :is="url ? 'nuxt-link' : 'div'" :to="url" :class="`update-counter update-counter--${icon}`">
        <asc-icon
            :icon="icon"
            :has-fill="icon === 'like'"
            :has-stroke="icon === 'comment'"
            class="update-counter__icon"
        />

        <div class="update-counter__count">
            <slot />
        </div>
    </component>
</template>

<script>
import AscIcon from '~/patterns/atoms/icon/icon.vue';

export default {
    components: { AscIcon },

    props: {
        url: {
            type: [Object, String],
            required: false,
            default: null
        },

        icon: {
            type: String,
            required: true,
            default: null,
            validator: input => input === 'like' || input === 'comment'
        }
    }
};
</script>

<style lang="less" src="./update-counter.less" />
